<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card elevation="0">
      <v-card-actions>
        <v-card-title>{{ formTitle }}</v-card-title>
        <v-spacer></v-spacer>
        <v-btn :to="{ name: 'Deals' }" color="green" class="" rounded>
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn :disabled="!valid" color="primary" @click="save" rounded>
          {{ $t("Save") }}
        </v-btn>
      </v-card-actions>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <!-- <v-select
                            :items="relations"
                            item-text="name"
                            item-value="id"
                            v-model="item.relationId"
                            :label="$t('Select Relation')"
                            :rules="rules.relationId"
                            required
                            >
                                <template v-slot:item="{item}">
                                    {{$t(item.name)}}
                                </template>
                            </v-select> -->

              <v-autocomplete
                v-model="item.relationId"
                :items="relations"
                item-text="name"
                item-value="id"
                :label="$t('Select Relation')"
                :rules="rules.relationId"
                required
                clearable
              >
              </v-autocomplete>
            </v-col>
            <v-col
              v-if="
                $store.state.user.user.role == 0 ||
                $store.state.user.user.role == 1
              "
              cols="12"
              sm="6"
              md="6"
            >
              <v-select
                :items="users"
                item-text="fullName"
                item-value="id"
                v-model="item.accountManagerId"
                :label="$t('Select an Account Manager')"
                :rules="rules.accountManagerId"
                required
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="offerDate"
                    :label="$t('Select a Offer Date')"
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="offerDateFormatted"
                  no-title
                  @input="menu2 = false"
                  :first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-menu
                v-model="menu3"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="orderDate"
                    :label="$t('Select a Order Date')"
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="orderDateFormatted"
                  no-title
                  @input="menu3 = false"
                  :first-day-of-week="1"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model.number="item.amount"
                type="number"
                min="0"
                :label="$t('Amount')"
                class=""
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model.number="item.grossMargin"
                type="number"
                min="0"
                :label="$t('Gross Margin')"
                class=""
              ></v-text-field>
            </v-col>

            <v-col
              v-for="customField in customFields"
              v-bind:key="customField.id"
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                v-if="customField.type == 0"
                v-model.number="customField.value"
                type="number"
                :label="$t(customField.name)"
                class=""
                :ref="customField.id"
                :rules="rules.customField"
              ></v-text-field>
              <v-text-field
                v-if="customField.type == 1"
                v-model="customField.value"
                type="text"
                :label="$t(customField.name)"
                class=""
                :ref="customField.id"
                :rules="rules.customField"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              class="d-flex justify-space-between"
            >
              <label class="">{{ $t("Order Lines") }}</label>
              <v-btn
                @click="addField(orderLines)"
                fab
                dark
                x-small
                color="primary"
                elevation="0"
                class="ml-2"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="">
                    {{ $t("Solution Type") }}
                  </th>
                  <th class="">
                    {{ $t("Amount") }}
                  </th>
                  <th class="">
                    {{ $t("Gross Margin") }}
                  </th>
                  <th class="">
                    {{ $t("Upsell") }}
                  </th>
                  <th class="">
                    {{ $t("Crossell") }}
                  </th>
                  <th class=""></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(input, index) in orderLines"
                  :key="`orderLineInput-${index}`"
                >
                  <td>
                    <v-select
                      :items="solutionTypes"
                      item-text="name"
                      item-value="id"
                      v-model="input.solutionTypeId"
                      clearable
                    ></v-select>
                  </td>
                  <td>
                    <v-text-field
                      v-model.number="input.amount"
                      type="number"
                      min="0"
                      class=""
                      small
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-model.number="input.grossMargin"
                      type="number"
                      min="0"
                      class=""
                      small
                    ></v-text-field>
                  </td>
                  <td>
                    <v-checkbox v-model="input.isUpsell"></v-checkbox>
                  </td>
                  <td>
                    <v-checkbox v-model="input.isCrosssell"></v-checkbox>
                  </td>
                  <td>
                    <v-btn
                      @click="removeField(index, orderLines)"
                      fab
                      dark
                      x-small
                      color="green ml-3"
                      elevation="0"
                    >
                      <v-icon dark> mdi-close </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      componentKey: 0,
      valid: true,
      item: {},
      menu2: false,
      menu3: false,
      offerDateFormatted: "",
      offerDate: null,
      orderDateFormatted: "",
      orderDate: null,
      orderLines: [],
      users: [],
      relations: [],
      solutionTypes: [],
      CFitems: [],
      customFields: [],
      id: "",
      loading: false,
      rules: {
        relationId: [(value) => !!value || "Required."],
        accountManagerId: [(value) => !!value || "Required."],
        amount: [(value) => !!value || "Required."],
      },
    };
  },

  computed: {
    formTitle() {
      return this.id == "" ? this.$t("Add New Deal") : this.$t("Edit Deal");
    },
  },
  watch: {
    orderDateFormatted() {
      this.orderDate = this.formatDate(this.orderDateFormatted);
    },
    offerDateFormatted() {
      this.offerDate = this.formatDate(this.offerDateFormatted);
    },
    offerDate() {
      this.offerDateFormatted = this.reFormatDate(this.offerDate);
    },
    orderDate() {
      this.orderDateFormatted = this.reFormatDate(this.orderDate);
    },
  },
  created: function () {
    this.fetchUsers();
    this.fetchRelations();
    this.fetchSolutionTypes();

    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getItem();
    } else {
      this.CFfetchItems();
    }
  },

  methods: {
    addField(fieldType) {
      fieldType.push({ solutionTypeId: "", amount: "", grossMargin: "" });
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    reFormatDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("-");
      return `${year}-${month}-${day}`;
    },
    increase(v) {
      this.orderLines[v].amount = parseInt(this.orderLines[v].amount) + 1;
    },
    decrease(v) {
      this.orderLines[v].amount =
        parseInt(this.orderLines[v].amount) <= 0
          ? 0
          : parseInt(this.orderLines[v].amount) - 1;
    },
    fetchUsers() {
      let uri = this.$urlPrefix + "/User/CountAll?includeDeleted=false";
      this.axios.get(uri).then((response) => {
        let uri2 =
          this.$urlPrefix +
          "/User/GetList?start=0&count=" +
          response.data +
          "&includeDeleted=false";
        this.axios.get(uri2).then((response) => {
          this.users = response.data.filter(function (user) {
            return user.role == 1 || user.role == 2;
          });
        });
      });
    },
    fetchRelations() {
      let uri = this.$urlPrefix + "/Relation/CountAll";
      this.axios.get(uri).then((response) => {
        let uri2 =
          this.$urlPrefix + "/Relation/GetList?start=0&count=" + response.data;
        this.axios.get(uri2).then((response) => {
          this.relations = response.data;
        });
      });
    },
    fetchSolutionTypes() {
      let uri = this.$urlPrefix + "/SolutionType/CountAll";
      this.axios.get(uri).then((response) => {
        let uri2 =
          this.$urlPrefix +
          "/SolutionType/GetList?start=0&count=" +
          response.data;
        this.axios.get(uri2).then((response) => {
          this.solutionTypes = response.data;
          this.loading = false;
        });
      });
    },

    CFfetchItems() {
      let uri = this.$urlPrefix + "/DealCustomField/CountAll";
      this.axios.get(uri).then((response) => {
        let uri2 =
          this.$urlPrefix +
          "/DealCustomField/GetList?start=0&count=" +
          response.data;
        this.axios.get(uri2).then((response) => {
          this.CFitems = response.data;
          this.customFields = [];
          this.CFitems.forEach((CFitem) => {
            this.customFields.push({
              id: CFitem.id,
              name: CFitem.name,
              type: CFitem.type,
              value: "",
            });
          });
        });
      });
    },

    getItem() {
      this.loading = true;
      let uri = this.$urlPrefix + "/Deal/Get?id=" + this.id;
      this.axios.get(uri).then((response) => {
        this.item = response.data;
        this.customFields = this.item.customFields;
        this.orderLines =
          this.item.orderLines == null ? [] : this.item.orderLines;

        if (this.item.offerDate) {
          this.offerDate = this.formatDate(
            new Date(this.item.offerDate).toISOString().substr(0, 10)
          );
          this.offerDateFormatted = new Date(this.item.offerDate)
            .toISOString()
            .substr(0, 10);
        }

        if (this.item.orderDate) {
          this.orderDate = this.formatDate(
            new Date(this.item.orderDate).toISOString().substr(0, 10)
          );
          this.orderDateFormatted = new Date(this.item.orderDate)
            .toISOString()
            .substr(0, 10);
          this.loading = false;
        }
      });
    },
    save() {
      this.item.orderLines = this.orderLines;
      this.item.offerDate = this.offerDateFormatted;
      this.item.orderDate = this.orderDateFormatted;
      this.item.customFields = [];
      for (var i in this.customFields) {
        let customField = this.customFields[i];
        customField.value = String(customField.value);
        this.item.customFields.push(customField);
      }

      if (this.$refs.form.validate()) {
        this.loading = true;

        if (this.item.orderDate == "") {
          this.item.orderDate = null;
        }

        if (this.item.offerDate == "") {
          this.item.offerDate = null;
        }

        if (this.id != "") {
          let uri = this.$urlPrefix + "/Deal/Update/";
          this.axios.put(uri, this.item).then((response) => {
            if (response.status == 204 || response.status == 200) {
              this.loading = false;
              this.$router.push({
                name: "Deals",
                query: { page: this.$route.query.page },
              });
            }
          });
        } else {
          let uri = this.$urlPrefix + "/Deal/Add";
          this.axios.post(uri, this.item).then((response) => {
            if (response.status == 200) {
              this.item = {};
              this.loading = false;
              this.$router.push({
                name: "Deals",
                query: { page: this.$route.query.page },
              });
            }
          });
        }
      }
    },
  },
};
</script>
<style lang="scss">
.orderLines-amount {
  width: 190px;
}
.number input {
  text-align: center;
}
.mp-btn span.v-btn__content {
  font-size: 20px;
}
</style>
